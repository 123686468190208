import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby';
import OptimizedImage from "../../utils/optimizedImage";
import { HeroContainer, BreadCrumbFlex, Container } from "./styles/HeroLegal.styled";

const HeroLegals = ({ title }) => {
  const backgroundData = useStaticQuery(graphql`
    query LegalHeroImage {
      contentfulComponentImage(id: {eq: "6bef6309-e288-5783-a1be-092ab249fa45"}) {
        __typename
        id
        primaryImage {
          gatsbyImageData
          file {
            url
          }
          description
        }
      }
    }
  `)

  const backgroundImage = backgroundData?.contentfulComponentImage?.primaryImage
  
  return (
    <HeroContainer
      data-comp={title}
    >
      <OptimizedImage image={backgroundImage?.gatsbyImageData} src={backgroundImage?.file?.url} alt="" className="hero-image" loading="eager" />
      <BreadCrumbFlex>
        <Link href="/"><span>Home</span></Link>
        <img src='/icons/chevron-right-white.svg' alt="Breadcrumb Chevron"/>
        <span>{title}</span>
      </BreadCrumbFlex>
      <Container>
        <h1>{title}</h1>
      </Container>
    </HeroContainer>
  )
}

export default HeroLegals
